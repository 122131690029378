import { defineComponent } from 'vue'

import './index.scss'
export default defineComponent({
  name: 'GfrButton',
  props: {
    url: String,
    disabled: Boolean,
    download: Boolean
  },
  emits: ['click'],
  setup(props, { slots, emit }) {
    const isExternal = (url: string) => /^(https?:|mailto:|tel:)/.test(url)
    const filename = computed(() => {
      const url = props.url
      return props.download ? url?.substring(url?.lastIndexOf('/') + 1, url.lastIndexOf('.')) : ''
    })
    const handleClick = () => {
      !props.disabled && emit('click')
    }
    return () =>
      props.url ? (
        <a
          class={'gfr-button'}
          href={props.url}
          onClick={handleClick}
          download={filename.value}
          target={isExternal(props.url) ? '_blank' : '_self'}
        >
          {slots.default?.()}
        </a>
      ) : (
        <button class={'gfr-button'} onClick={handleClick}>
          {slots.default?.()}
        </button>
      )
  }
})
